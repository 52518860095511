import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./context/userContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { userInputs, sliderInputs, blogInputs } from "./formSource";
import { 
  Home, Query, Quote, Testimonial, TestimonialForm,TestimonialFormDetail,TestimonialFormUpdate, QueryDetail, QuoteDetail, TestimonialDetail, 
  TestimonialUpdate, Client, Newclient, ClientDetail, ClientUpdate, NewTest, Slider, 
  SliderUpdate, Newslider, SliderDetail, Login, Profile, QuoteUpdate, QueryUpdate, 
  ServiceDetail, ServiceUpdate 
} from './pages/index';
import "./style/dark.scss";
import { DarkModeContext } from "./context/darkModeContext";
import CircularProgress from '@mui/material/CircularProgress';
import Blog from "./pages/blog/Blog";
import NewBlog from "./pages/blog/NewBlog";
import BlogDetail from "./pages/blog/BlogDetail";
import BlogUpdate from "./pages/blog/BlogUpdate";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { user, loading } = useContext(UserContext); // Get loading from UserContext

  useEffect(() => {
    if (!loading && !user) { // Check loading state before navigating
      navigate("/");
    }
  }, [user, navigate, loading]); // Add loading to dependencies

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center vh-100"><CircularProgress /> </div>; // Render loading spinner
  }

  return user ? children : null;
};

function App() {
  const { darkMode } = useContext(DarkModeContext);
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/query/:id" element={<PrivateRoute><QueryDetail /></PrivateRoute>} />
          <Route path="/query/update/:id" element={<PrivateRoute><QueryUpdate /></PrivateRoute>} />
          <Route path="/queries" element={<PrivateRoute><Query /></PrivateRoute>} />
          <Route path="/quote/:id" element={<PrivateRoute><QuoteDetail /></PrivateRoute>} />
          <Route path="/quote/update/:id" element={<PrivateRoute><QuoteUpdate /></PrivateRoute>} />
          <Route path="/service/:id" element={<PrivateRoute><ServiceDetail /></PrivateRoute>} />
          <Route path="/service/update/:id" element={<PrivateRoute><ServiceUpdate /></PrivateRoute>} />
          <Route path="/services" element={<PrivateRoute><Quote /></PrivateRoute>} />
          <Route path="/testimonial/:id" element={<PrivateRoute><TestimonialDetail /></PrivateRoute>} />
          <Route path="/testimonial/update/:id" element={<PrivateRoute><TestimonialUpdate /></PrivateRoute>} />
          <Route path="/testimonials" element={<PrivateRoute><Testimonial /></PrivateRoute>} />
          <Route path="/new-testimonials" element={<PrivateRoute><TestimonialForm /></PrivateRoute>} />
          <Route path="/new-testimonial/:id" element={<PrivateRoute><TestimonialFormDetail /></PrivateRoute>} />
          <Route path="/new-testimonial/update/:id" element={<PrivateRoute><TestimonialFormUpdate /></PrivateRoute>} />
          <Route path="/new" element={<PrivateRoute><NewTest inputs={userInputs} title="Add New Testimonial" /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute><Client /></PrivateRoute>} />
          <Route path="/client/:id" element={<PrivateRoute><ClientDetail /></PrivateRoute>} />
          <Route path="/client/update/:id" element={<PrivateRoute><ClientUpdate /></PrivateRoute>} />
          <Route path="/newclient" element={<PrivateRoute><Newclient title="Add New Client Logo" /></PrivateRoute>} />
          <Route path="/slider" element={<PrivateRoute><Slider /></PrivateRoute>} />
          <Route path="/slider/:id" element={<PrivateRoute><SliderDetail /></PrivateRoute>} />
          <Route path="/slider/update/:id" element={<PrivateRoute><SliderUpdate /></PrivateRoute>} />
          <Route path="/newslider" element={<PrivateRoute><Newslider inputs={sliderInputs} title="Add New Slider Image" /></PrivateRoute>} />
          <Route path="/blog" element={<PrivateRoute><Blog /></PrivateRoute>} />
          <Route path="/newblog" element={<PrivateRoute><NewBlog inputs={blogInputs} title="Add New Blog" /></PrivateRoute>} />
          <Route path="/blog/:id" element={<PrivateRoute><BlogDetail /></PrivateRoute>} />
          <Route path="/blog/update/:id" element={<PrivateRoute><BlogUpdate /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
      </div>
  );
}

export default App;