import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../clientdata/clientdatasource";
import { getStorage, ref, deleteObject } from "firebase/storage";
import "./clientdata.scss";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { format, formatDistanceToNow } from "date-fns";

import { db } from '../../../firebase';
import { Link } from "react-router-dom";

const Clientdata = () => {
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  useEffect(() => {
    const clientsDoc = doc(db, "clients", "clientDoc");

    const unsubscribe = onSnapshot(clientsDoc, (docSnap) => {
      if (docSnap.exists()) {
        setData(docSnap.data().clients.map((item, index) => ({
          ...item,
          id: index + 1,
          timestamp: item.timestamp
            ? `${format(new Date(item.timestamp.seconds * 1000), 'dd-MMMM-yyyy')}`
      : "Not available",
      duration: item.timestamp
            ? `${formatDistanceToNow(new Date(item.timestamp.seconds * 1000), { addSuffix: true })}`
      : "Not available",
        })));
      } else {
        console.log('No such document!');
        setData([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);


  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async (id) => {
    // Get the item to be deleted
    const item = data.find((item) => item.id === id);
  
    // Filter out the item to be deleted
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  
    // Update the document in Firestore
    const clientsDoc = doc(db, "clients", "clientDoc");
    const oldData = (await getDoc(clientsDoc)).data().clients;
    oldData.splice(id - 1, 1); // remove the item at index id - 1
    await updateDoc(clientsDoc, { clients: oldData });
  
    // Delete the file from Firebase Storage
    const storage = getStorage();
    const storageRef = ref(storage, 'clients/' + item.fileName);
    await deleteObject(storageRef);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/client/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <Link to={`/client/update/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="updateButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="clientdata">
      <div className="clientdataTitle">
        Clients
        <Link to="/newclient" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this client?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Clientdata;