import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const TestimonialFormUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [positiveReview, setPositiveReview] = useState("");
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const testimonialDoc = doc(db, "formData", "testimonialDoc");
      const testimonialData = (await getDoc(testimonialDoc)).data();
      const index = id - 1;

      if (
        testimonialData &&
        testimonialData.testimonials &&
        testimonialData.testimonials[index]
      ) {
        const testimonial = testimonialData.testimonials[index];
        setFullName(testimonial.fullName);
        setDesignation(testimonial.designation);
        setCompanyName(testimonial.companyName);
        setCompanyEmail(testimonial.companyEmail);
        setPositiveReview(testimonial.positiveReview);
        setFeedback(testimonial.feedback);
      } else {
        console.error(`No testimonial found with ID: ${id}`);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const index = id - 1;
      const testimonialDoc = doc(db, "formData", "testimonialDoc");
      const testimonialData = (await getDoc(testimonialDoc)).data();

      if (testimonialData && testimonialData.testimonials) {
        const testimonials = testimonialData.testimonials;
        testimonials[index] = {
          fullName,
          designation,
          companyName,
          companyEmail,
          positiveReview,
          feedback,
        };

        await updateDoc(testimonialDoc, { testimonials });
      }

      navigate(`/new-testimonial/${id}`);
    } catch (error) {
      console.error("Error updating testimonial: ", error);
    }
  };

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="new">
          <div className="newContainer">
            <div className="top">
              <h1>Update Testimonial</h1>
            </div>
            <div className="bottom">
              <div className="right">
                <form onSubmit={handleSubmit}>
                  <div className="formInput">
                    <label>Full Name</label>
                    <input
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Designation</label>
                    <input
                      type="text"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Company Name</label>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Company Email</label>
                    <input
                      type="email"
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Positive Review</label>
                    <textarea
                      value={positiveReview}
                      onChange={(e) => setPositiveReview(e.target.value)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Feedback</label>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />
                  </div>
                  <Link to="/new-testimonials">
                    <button>Go Back</button>
                  </Link>
                  <br />
                  <button type="submit">Update</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialFormUpdate;
