import "./slider.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Sliderdata from "../../components/data/sliderdata/Sliderdata"
import useMediaQuery from '@mui/material/useMediaQuery';
import Mnavbar from "../../components/mobile-navbar/Mnavbar"; 

const Slider = () => {
  const matches = useMediaQuery('(max-width:600px)'); 
  return (
    <div className="slider">
      {!matches && <Sidebar />}
      <div className="sliderContainer">
      {matches ? <Mnavbar /> : <Navbar />}
        <Sliderdata/>
      </div>
    </div>
  )
}

export default Slider