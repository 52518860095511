import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "title1",
    headerName: "Title 1",
    width: 180,
  },
  {
    field: "title2",
    headerName: "Title 2",
    width: 340,
  },
  {
    field: "sliderImg",
    headerName: "Slider Image",
    width: 400,
  },
];

export const fetchData = async () => {
  const docRef = doc(db, 'slider', 'sliderDoc');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().slider.map((item, index) => ({
      ...item,
      id: index+1,
    }));
  } else {
    console.log('No such document!');
    return [];
  }
};