import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { serviceColumns } from "../servicedata/servicedatasource";
import "./servicedata.scss";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const ServiceData = () => {
  const [serviceData, setServiceData] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/service/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/service/update/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const serviceDoc = doc(db, "formData", "serviceDoc");
  
    const unsubscribe = onSnapshot(serviceDoc, (docSnap) => {
      if (docSnap.exists()) {
        const rawData = docSnap.data().service;
        const filteredData = rawData.filter(item => item.isVisible !== false); // Filter based on isVisible
        setServiceData(
          filteredData.map((item, index) => {
            const formattedDate = item.timestamp
              ? `${format(
                  new Date(item.timestamp.seconds * 1000),
                  "dd-MMMM-yyyy || h:mm a"
                )} • ${formatDistanceToNow(
                  new Date(item.timestamp.seconds * 1000),
                  { addSuffix: true }
                )}`
              : "Timestamp not available";
  
            return {
              ...item,
              id: index + 1,
              timestamp: formattedDate,
            };
          })
        );
      } else {
        console.log("No such document!");
        setServiceData([]);
      }
    });
  
    return () => unsubscribe();
  }, []);

  // Example of adjusting a column definition for timestamp
  const serviceColumnsWithCustomTimestamp = serviceColumns.map((column) => {
    if (column.field === "timestamp") {
      // Assuming there's a 'timestamp' field
      return {
        ...column,
        renderCell: (params) => (
          <div>
            {params.value.split("•").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index === 0 && <br />}
              </React.Fragment>
            ))}
          </div>
        ),
      };
    }
    return column;
  });

  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async (id) => {
    // Update the local state to set isVisible to false for the deleted item
    const updatedServiceData = serviceData.map((item) => {
      if (item.id === id) {
        return { ...item, isVisible: false };
      }
      return item;
    });
    setServiceData(updatedServiceData);

    // Find the item in the Firestore document and update its isVisible property
    const serviceDoc = doc(db, "formData", "serviceDoc");
    const docSnap = await getDoc(serviceDoc);
    if (docSnap.exists()) {
      const oldServiceData = docSnap.data().service;
      const updatedServiceDataInDb = oldServiceData.map((item, index) => {
        if (index === id - 1) {
          // Assuming id is 1-indexed and matches array positions
          return { ...item, isVisible: false };
        }
        return item;
      });
      await updateDoc(serviceDoc, { service: updatedServiceDataInDb });
    }
  };

  return (
    <div className="servicedata">
      <div className="servicedataTitle">Request A Call-back Form Data</div>
      <DataGrid
        className="datagrid"
        rows={serviceData}
        columns={serviceColumnsWithCustomTimestamp.concat(actionColumn)}
        pageSize={15}
        rowsPerPageOptions={[15]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this form data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ServiceData;
