import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const SliderUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sliderData, setSliderData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const sliderDoc = doc(db, "slider", "sliderDoc");
      const sliderData = (await getDoc(sliderDoc)).data();
      const index = id - 1;

      if (sliderData && sliderData.slider && sliderData.slider[index]) {
        setSliderData(sliderData.slider[index]);
      } else {
        console.error(`No slider found with ID: ${id}`);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imgUrl = "";

      if (sliderData.sliderImg instanceof Blob) {
        const storageRef = ref(storage, `slider/${sliderData.sliderImg.name}`);
        const uploadTask = uploadBytesResumable(
          storageRef,
          sliderData.sliderImg
        );

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              reject(error);
            },
            async () => {
              imgUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } else {
        imgUrl = sliderData.sliderImg;
      }

      const index = id - 1;
      const sliderDoc = doc(db, "slider", "sliderDoc");
      const sliderDataArray = (await getDoc(sliderDoc)).data().slider;

      if (sliderDataArray) {
        sliderDataArray[index] = {
          ...sliderData,
          sliderImg: imgUrl,
        };

        await updateDoc(sliderDoc, { slider: sliderDataArray });
      }

      navigate(`/slider/${id}`);
    } catch (error) {
      console.error("Error updating slider: ", error);
    }
  };

  const handleInputChange = (e) => {
    setSliderData({
      ...sliderData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setSliderData({
      ...sliderData,
      sliderImg: e.target.files[0],
    });
  };

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="new">
          <div className="newContainer">
            <div className="top">
              <h1>Update Slider</h1>
            </div>
            <div className="bottom">
              <div className="left">
                <img
                  src={
                    sliderData.sliderImg instanceof Blob
                      ? URL.createObjectURL(sliderData.sliderImg)
                      : sliderData.sliderImg ||
                        "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="right">
                <form onSubmit={handleSubmit}>
                  <div className="formInput">
                    <label htmlFor="file">
                      Upload Img
                      <br />
                      (Suggested dimensions:<br/> 1920px x 1080px):{" "}
                      <DriveFolderUploadOutlinedIcon className="icon" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="sliderImg"
                      onChange={(e) => handleFileChange(e)}
                      style={{ display: "none" }}
                    />
                  </div>

                  <div className="formInput">
                    <label>Title 1</label>
                    <input
                      type="text"
                      name="title1"
                      value={sliderData.title1 || ""}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Title 2</label>
                    <input
                      type="text"
                      name="title2"
                      value={sliderData.title2 || ""}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <button type="submit">Update Slider</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderUpdate;