import React, { useState } from "react";
import "./Mnavbar.scss";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import Msidebar from "../mobile-sidebar/Msidebar";
import { NavLink } from "react-router-dom";
import logo from "../sidebar/logo.png";

const Mnavbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div className="navbar">
        <div className="wrapper">
          <NavLink to="/home" style={{ textDecoration: "none" }}>
            <img className="top" src={logo} alt="Logo" />
          </NavLink>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 3 }}
            onClick={toggleSidebar}
          >
            {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <div className="items"></div>
        </div>
      </div>
      {sidebarOpen && <Msidebar open={sidebarOpen} onClose={toggleSidebar} />}
    </>
  );
};

export default Mnavbar;