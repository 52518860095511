import React, { useEffect, useState } from "react";
import "./chart.scss";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const fetchDocumentData = async (collection, docName, arrayName) => {
  const db = getFirestore();
  const docRef = await getDoc(doc(db, collection, docName));
  if (docRef.exists()) {
    const data = docRef.data()[arrayName];
    console.log(`Data for ${docName}:`, data); // Debug log
    return data || [];
  }
  return [];
};

const processData = (data, docType) => {
  return data
    .filter((item) => {
      // Exclude items where isVisible is false
      if (item.isVisible === false) {
        return false;
      }
      return docType === "blog" ? item.date : item.timestamp;
    })
    .map((item, index) => {
      let date;
      if (docType === "blog") {
        date = new Date(item.date);
        if (isNaN(date.getTime())) {
          console.error(`Invalid date for blog entry: ${item.date}`);
          return null;
        }
      } else {
        date = item.timestamp.toDate();
      }
      return {
        x: date.getTime(),
        y: index + 1,
        docType,
        name: docType === "testimonial" ? item.fullName : (item.title || item.name),
      };
    })
    .filter(item => item !== null)
    .sort((a, b) => a.x - b.x);
};

const Chart = ({ aspect }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const docs = [
        { collection: "formData", docName: "contactDoc", arrayName: "contact" },
        { collection: "formData", docName: "quotesDoc", arrayName: "quotes" },
        { collection: "formData", docName: "serviceDoc", arrayName: "service" },
        { collection: "formData", docName: "testimonialDoc", arrayName: "testimonials" },
        { collection: "blogs", docName: "blogDoc", arrayName: "blogs" },
      ];

      const results = await Promise.all(
        docs.map((doc) =>
          fetchDocumentData(doc.collection, doc.docName, doc.arrayName)
        )
      );

      const processedData = results.flatMap((result, index) => {
        const docType = docs[index].docName.replace("Doc", "");
        console.log(`Raw data for ${docType}:`, result); // Debug log
        const processed = processData(result, docType);
        console.log(`Processed data for ${docType}:`, processed); // Debug log
        if (processed.length === 0) {
          console.warn(`No visible data for ${docType}`);
        }
        return processed;
      });

      setData(processedData);
    };

    fetchData();
  }, []);

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${data.docType}: ${data.y}`}</p>
          <p>{`Name: ${data.name}`}</p>
          <p>{`Date: ${formatXAxis(data.x)}`}</p>
        </div>
      );
    }
    return null;
  };

  const colors = {
    contact: "green",
    quotes: "orange",
    service: "orange",
    testimonial: "turquoise",
    blog: "purple",
  };

  return (
    <div className="chart">
      <div className="title">Data Entry Timeline</div>
      <ResponsiveContainer width="100%" height={300}>
        <ScatterChart margin={{ top: 20, right: 30, left: 30, bottom: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            type="number"
            domain={["auto", "auto"]}
            tickFormatter={formatXAxis}
            label={{ value: "Date", position: "bottom" }}
          />
          <YAxis
            dataKey="y"
            type="number"
            label={{ value: "Entry Count", angle: -90, position: "insideLeft" }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="bottom"
            layout="horizontal"
            wrapperStyle={{
              bottom: -30,
              position: "absolute",
            }}
          />
          {Object.entries(colors).map(([docType, color]) => (
            <Scatter
              key={docType}
              name={docType.charAt(0).toUpperCase() + docType.slice(1)}
              data={data.filter((item) => item.docType === docType)}
              fill={color}
              line={{ stroke: color }}
              lineJointType="monotoneX"
            >
              <Line type="monotone" dataKey="y" stroke={color} />
            </Scatter>
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
