import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "clientLogo",
    headerName: "Client Logo",
    width: 220,
    renderCell: (params) => {
      return <img src={params.value} alt="Client Logo" style={{ width: '120px', height: 'auto' }} />;
    },
  },
  { field: "fileName", headerName: "Logo File Name", width: 280 },
  {
    field: "timestamp",
    headerName: "Joined Date",
    width: 160,   
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 220,
  }
];

export const fetchData = async () => {
  const docRef = doc(db, 'clients', 'clientDoc');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().clients.map((item, index) => ({
      ...item,
      id: index+1,
    }));
  } else {
    console.log('No such document!');
    return [];
  }
};