import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const blogColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "title",
    headerName: "Title",
    width: 230,
  },
  {
    field: "metaDescription",
    headerName: "Meta Description",
    width: 230,
  },
  {
    field: "author",
    headerName: "Author",
    width: 130,
  },
  {
    field: "date",
    headerName: "Date",
    width: 150,
  },
  {
    field: "labels",
    headerName: "Labels",
    width: 150,
  },
];

export const fetchData = async () => {
  const docRef = doc(db, 'formData', 'indiserviceDoc'); // changed to indiserviceDoc
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().service.map((item, index) => ({
      ...item,
      id: index+1,
    }));
  } else {
    console.log('No such document!');
    return [];
  }
};