import React, { useState } from 'react';
import "./quote.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Quotedata from "../../components/data/quotedata/Quotedata"
import ServiceData from "../../components/data/servicedata/ServiceData"
import AllServiceData from "../../components/data/allservicedata/AllServiceData"
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Mnavbar from "../../components/mobile-navbar/Mnavbar"; 

const Quote = () => {
  const [activeComponent, setActiveComponent] = useState('both');
  const matches = useMediaQuery('(max-width:600px)'); 

  return (
    <div className="quote">
      {!matches && <Sidebar />}
      <div className="quoteContainer">
      {matches ? <Mnavbar /> : <Navbar />}
        <Link 
          className={`myLink ${activeComponent === 'both' ? 'active' : ''}`} 
          onClick={() => setActiveComponent('both')}
        >
          Form Data(All)
        </Link>
        <Link 
          className={`myLink ${activeComponent === 'service' ? 'active' : ''}`} 
          onClick={() => setActiveComponent('service')}
        >
          Service Data
        </Link>
        <Link 
          className={`myLink ${activeComponent === 'quote' ? 'active' : ''}`} 
          onClick={() => setActiveComponent('quote')}
        >
          Quote Data
        </Link>
        <hr className='m-1'/>
        {activeComponent === 'service' && <ServiceData/>}
        {activeComponent === 'quote' && <Quotedata/>}
        {activeComponent === 'both' && <AllServiceData/>}
      </div>
    </div>
  );
};

export default Quote