import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const BlogUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    metaDescription: "",
    labels: "",
    author: "",
    date: "",
    content: EditorState.createEmpty(),
    imageUrl: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const blogsDoc = doc(db, "blogs", "blogDoc");
      const blogData = (await getDoc(blogsDoc)).data();
      const index = id - 1;
  
      if (blogData && blogData.blogs && blogData.blogs[index]) {
        const blog = blogData.blogs[index];
        let contentState;
        
        try {
          // Try to parse the content as JSON
          const parsedContent = JSON.parse(blog.content);
          contentState = convertFromRaw(parsedContent);
        } catch (error) {
          // If parsing fails, treat the content as plain text
          contentState = EditorState.createWithText(blog.content);
        }
  
        setFormData({
          title: blog.title || "",
          metaDescription: blog.metaDescription || "",
          labels: blog.labels || "",
          author: blog.author || "",
          date: blog.date || "",
          content: EditorState.createWithContent(contentState),
          imageUrl: blog.imageUrl || "",
        });
      } else {
        console.error(`No blog found with ID: ${id}`);
      }
    };
  
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title" && value.length > 80) return;
    if (name === "metaDescription" && value.length > 150) return;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditorChange = (editorState) => {
    setFormData({
      ...formData,
      content: editorState,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history",
    ],
    inline: {
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "monospace",
        "superscript",
        "subscript",
      ],
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let fileUrl = formData.imageUrl;

      if (file) {
        const storageRef = ref(storage, "blog/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              reject(error);
            },
            () => {
              resolve();
            }
          );
        });

        fileUrl = await getDownloadURL(storageRef);
      }

      const contentState = formData.content.getCurrentContent();
      const rawContent = JSON.stringify(convertToRaw(contentState));

      const data = {
        ...formData,
        imageUrl: fileUrl,
        content: rawContent,
      };

      for (const [key, value] of Object.entries(data)) {
        if (!value && key !== "imageUrl") {
          alert(`Field ${key} is empty`);
          setIsLoading(false);
          return;
        }
      }

      const blogsDoc = doc(db, "blogs", "blogDoc");
      const blogData = (await getDoc(blogsDoc)).data();

      if (blogData && blogData.blogs) {
        const blogs = blogData.blogs;
        blogs[id - 1] = data;

        await updateDoc(blogsDoc, { blogs });
      }

      alert("Blog updated successfully");
      navigate(`/blog/${id}`);
    } catch (error) {
      console.error("Error updating blog: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
          <div className="blog-detail">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="new">
          <div className="newContainer">
            <div className="top">
              <h1>Update Blog</h1>
            </div>
            <div className="bottom">
              <div className="left">
                <img
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : formData.imageUrl || "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="right">
                <form onSubmit={handleSubmit}>
                  <div className="formInput text-center">
                    <label htmlFor="file">
                      Upload Image (1920px x 1080px):{" "}
                      <DriveFolderUploadOutlinedIcon className="icon fs-2" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>

                  <div className="formInput">
                    <label>Title (Max 80 characters)</label>
                    <textarea
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      rows={1}
                      maxLength={80}
                    />
                  </div>

                  <div className="formInput">
                    <label>Meta Description (Max 150 characters)</label>
                    <textarea
                      name="metaDescription"
                      value={formData.metaDescription}
                      onChange={handleChange}
                      rows={2}
                      maxLength={150}
                    />
                  </div>

                  <div className="formInput">
                    <label>Labels (comma separated)</label>
                    <input
                      type="text"
                      name="labels"
                      value={formData.labels}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="formInput">
                    <label>Date</label>
                    <input type="text" name="date" value={formData.date} readOnly />
                  </div>

                  <div className="formInput">
                    <label>Author</label>
                    <input
                      type="text"
                      name="author"
                      value={formData.author}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="formInput">
                    <label>Content</label>
                    <Editor
                      editorState={formData.content}
                      onEditorStateChange={handleEditorChange}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={toolbarOptions}
                      editorStyle={{
                        minHeight: "400px",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>

                  <button type="submit">Update Blog</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogUpdate;