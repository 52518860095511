import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const QuoteUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [service, setService] = useState("");


  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const quotesDoc = doc(db, "formData", "quotesDoc");
      const quotesData = (await getDoc(quotesDoc)).data();
      const index = id - 1;
  
      if (quotesData && quotesData.quotes && quotesData.quotes[index]) {
        const quote = quotesData.quotes[index];
        setName(quote.name);
        setEmail(quote.email);
        setMobile(quote.mobile);
        setService(quote.service);
      } else {
        console.error(`No quote found with ID: ${id}`);
      }
    };
  
    fetchData();
  }, [id]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const index = id - 1;
      const quotesDoc = doc(db, "formData", "quotesDoc");
      const quotesData = (await getDoc(quotesDoc)).data();
  
      if (quotesData && quotesData.quotes) {
        const quotes = quotesData.quotes;
        quotes[index] = {
          name,
          email,
          mobile,
          service,
        };
  
        await updateDoc(quotesDoc, { quotes });
      }
  
      if (isMounted.current) {
        navigate(`/quote/${id}`);
      }
    } catch (error) {
      console.error("Error updating quote: ", error);
    }
  };

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="new">
  <div className="newContainer">
    <div className="top">
      <h1>Update Quote</h1>
    </div>
    <div className="bottom">
      <div className="right">
        <form onSubmit={handleSubmit}>
          <div className="formInput">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Mobile</label>
            <input
            type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Service</label>
            <input
              type="text"
              value={service}
              onChange={(e) => setService(e.target.value)}
            />
          </div>
          <button onClick={() => navigate(-1)}>Go Back</button>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
      </div>
  );
};

export default QuoteUpdate;