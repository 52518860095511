import "./allservicedata.scss";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const AllServiceData = () => {
  const [allData, setAllData] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Name", width: 120 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "mobile", headerName: "Mobile", width: 150 },
    {
      field: "combinedServices",
      headerName: "Services",
      width: 240,
      valueGetter: (params) => params.value,
    },
    { field: "timestamp", headerName: "Timestamp", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const isService = params.row.id.startsWith("S");
        const id = params.row.id.slice(1);

        return (
          <div className="cellAction">
            <Link
              to={`/${isService ? "service" : "quote"}/${id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/${isService ? "service/update" : "quote/update"}/${id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const unsubscribeServices = onSnapshot(doc(db, "formData", "serviceDoc"), (docSnap) => {
      let serviceData = [];
      if (docSnap.exists()) {
        serviceData = docSnap.data().service.filter(item => item.isVisible !== false);
      }
  
      const unsubscribeQuotes = onSnapshot(doc(db, "formData", "quotesDoc"), (docSnap) => {
        let quotesData = [];
        if (docSnap.exists()) {
          quotesData = docSnap.data().quotes.filter(item => item.isVisible !== false);
        }
  
        const combinedData = [
          ...serviceData.map((item, index) => ({
            ...item,
            combinedServices: item.services ? item.services.join(", ") : "",
            id: "S" + (index + 1),
            timestamp: item.timestamp
              ? `${format(
                  new Date(item.timestamp.seconds * 1000),
                  "dd-MMMM-yyyy || h:mm a"
                )} • ${formatDistanceToNow(
                  new Date(item.timestamp.seconds * 1000),
                  { addSuffix: true }
                )}`
              : "Timestamp not available",
          })),
          ...quotesData.map((item, index) => ({
            ...item,
            combinedServices: item.service,
            id: "Q" + (index + 1),
            timestamp: item.timestamp
              ? `${format(
                  new Date(item.timestamp.seconds * 1000),
                  "dd-MMMM-yyyy || h:mm a"
                )} • ${formatDistanceToNow(
                  new Date(item.timestamp.seconds * 1000),
                  { addSuffix: true }
                )}`
              : "Timestamp not available",
          })),
        ];
  
        setAllData(combinedData);
      });
  
      return () => {
        unsubscribeQuotes();
      };
    });
  
    return () => {
      unsubscribeServices();
    };
  }, []);

  // Example of adjusting a column definition for timestamp
const columnsWithCustomTimestamp = columns.map(column => {
  if (column.field === "timestamp") { // Assuming there's a 'timestamp' field
    return {
      ...column,
      renderCell: (params) => (
        <div>
          {params.value.split('•').map((line, index) => (
            <React.Fragment key={index}>
              {line}{index === 0 && <br />}
            </React.Fragment>
          ))}
        </div>
      )
    };
  }
  return column;
});


  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };
  const handleDeleteConfirmed = async (id) => {
    const isService = id.startsWith("S");
    const itemId = parseInt(id.slice(1)) - 1; // Assuming IDs are like 'S1', 'Q1', etc.
  
    if (isService) {
      // Handle update for a service
      const serviceDocRef = doc(db, "formData", "serviceDoc");
      const docSnap = await getDoc(serviceDocRef);
      if (docSnap.exists()) {
        let services = docSnap.data().service;
        if (services[itemId]) {
          services[itemId].isVisible = false; // Set isVisible to false
        }
        await updateDoc(serviceDocRef, { service: services });
      }
    } else {
      // Handle update for a quote
      const quotesDocRef = doc(db, "formData", "quotesDoc");
      const docSnap = await getDoc(quotesDocRef);
      if (docSnap.exists()) {
        let quotes = docSnap.data().quotes;
        if (quotes[itemId]) {
          quotes[itemId].isVisible = false; // Set isVisible to false
        }
        await updateDoc(quotesDocRef, { quotes: quotes });
      }
    }
  
    // Update the local state to reflect the change
    const updatedData = allData.map(item => {
      if (item.id === id) {
        return { ...item, isVisible: false };
      }
      return item;
    }).filter(item => item.isVisible !== false); // Optionally filter out non-visible items
    setAllData(updatedData);
  };

  return (
    <div className="allservicedata">
      <div className="allservicedataTitle">All Service Forms Data</div>
      <DataGrid
        className="datagrid"
        rows={allData}
        columns={columnsWithCustomTimestamp}
        pageSize={15}
        rowsPerPageOptions={[15]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this form data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllServiceData;
