import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const BlogDetail = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogsDoc = doc(db, "blogs", "blogDoc");
        const data = (await getDoc(blogsDoc)).data().blogs;
        const blog = data[Number(id) - 1];
        setBlogData(blog);
        // Convert the content from raw state to EditorState
        if (blog.content) {
          const contentState = convertFromRaw(JSON.parse(blog.content));
          setEditorState(EditorState.createWithContent(contentState));
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [id]);

  if (!blogData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
          <div className="blog-detail">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="blog-detail">
          <h1>Testimonial Detail</h1>
          <img src={blogData.imageUrl} alt="Not found!" />
          <hr />
          <p>
            <strong>Name:</strong> {blogData.title}
          </p>
          <hr />
          <p>
            <strong>Descrption:</strong> {blogData.metaDescription}
          </p>
          <hr />
          <p>
            <strong>Author Name:</strong> {blogData.author}
          </p>
          <hr />
          <p>
            <strong>Content:</strong>{" "}
            {editorState && (
              <Editor
                editorState={editorState}
                readOnly={true}
                toolbarHidden
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
              />
            )}
          </p>
          <hr />
          <p>
            <strong>Date:</strong> {blogData.date}
          </p>
          <hr />
          <p>
            <strong>Labels:</strong> {blogData.labels}
          </p>
          <Link to="/blog">
            <button>Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
