import { useEffect, useState } from "react";
import "./widget.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CommentIcon from "@mui/icons-material/Comment";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Link } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export const fetchLatestUpdates = async (type) => {
  const db = getFirestore();

  let docNames, collectionName, arrayNames;
  switch (type) {
    case "client":
      docNames = ["clientDoc"];
      collectionName = "clients";
      arrayNames = ["clients"];
      break;
    case "testimonial":
      docNames = ["testDoc"];
      collectionName = "testimonials";
      arrayNames = ["testimonials"];
      break;
    case "slider":
      docNames = ["sliderDoc"];
      collectionName = "slider";
      arrayNames = ["slider"];
      break;
    case "contact":
      docNames = ["contactDoc"];
      collectionName = "formData";
      arrayNames = ["contact"];
      break;
    case "service":
      docNames = ["serviceDoc", "quotesDoc"];
      collectionName = "formData";
      arrayNames = ["service", "quotes"];
      break;
      case "blog":
      docNames = ["blogDoc"];
      collectionName = "blogs";
      arrayNames = ["blogs"];
      break;
    default:
      throw new Error(`Invalid type: ${type}`);
  }

  // Fetch data from the specified collection
  let totalItems = 0;
  for (let i = 0; i < docNames.length; i++) {
    const docSnap = await getDoc(doc(db, collectionName, docNames[i]));
    const data = docSnap.data()?.[arrayNames[i]];
    
    if (Array.isArray(data)) {
      // Filter out items where isVisible is false
      const visibleItems = data.filter(item => item.isVisible !== false);
      totalItems += visibleItems.length;
    } else {
      console.warn(`Data for ${arrayNames[i]} is not an array or is undefined`);
    }
  }

  // Return the total number of items in the arrays
  return totalItems;
};

const Widget = ({ type }) => {
  const [numItems, setNumItems] = useState(0);

  useEffect(() => {
    fetchLatestUpdates(type).then((num) => {
      setNumItems(num);
    });
  }, [type]);

  //temporary
  let data;

  switch (type) {
    case "slider":
      data = {
        title: "SLIDERS",
        links: [
          { text: "View All", path: "/slider" },
        ],
        icon: (
          <SlideshowIcon
            className="icon"
            style={{
              backgroundColor: "rgba(0, 0, 255, 0.2)",
              color: "blue",
            }}
          />
        ),
      };
      break;
    case "client":
      data = {
        title: "CLIENTS",
        links: [
          { text: "View All", path: "/clients" },
        ],
        icon: (
          <AccountCircleIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "testimonial":
      data = {
        title: "TESTIMONIALS",
        links: [
          { text: "View All", path: "/testimonials" },
        ],
        icon: (
          <CommentIcon
            className="icon"
            style={{
              backgroundColor: "rgba(0, 255, 255, 0.2)",
              color: "turquoise",
            }}
          />
        ),
      };
      break;
    case "contact":
      data = {
        title: "CONTACT FORM",
        links: [{ text: "View All", path: "/queries" }],
        icon: (
          <ContactSupportIcon
            className="icon"
            style={{
              backgroundColor: "rgba(0, 128, 0, 0.2)",
              color: "green",
            }}
          />
        ),
      };
      break;
    case "service":
      data = {
        title: "SERVICES FORM",
        links: [{ text: "View All", path: "/services" }],
        icon: (
          <ContactMailIcon
            className="icon"
            style={{
              backgroundColor: "rgba(255, 165, 0, 0.2)",
              color: "orange",
            }}
          />
        ),
      };
      break;
      case "blog":
      data = {
        title: "BLOGS",
        links: [{ text: "View All", path: "/blog" }],
        icon: (
          <EditNoteIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
    <div className="left">
      <span className="title">{data.title}</span>
      <div className="counter-links">
        <span className="counter">
          {data.isMoney && "$"} {numItems}
        </span>
        <div className="links">
          {data.links.map((link, index) => (
            <Link key={index} to={link.path} className="link">
              {link.text}
            </Link>
          ))}
        </div>
      </div>
    </div>
    <div className="right">{data.icon}</div>
  </div>
  );
};

export default Widget;
