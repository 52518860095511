import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const QueryUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const contactDoc = doc(db, "formData", "contactDoc");
      const contactData = (await getDoc(contactDoc)).data();
      const index = id - 1;
  
      if (contactData && contactData.contact && contactData.contact[index]) {
        const contact = contactData.contact[index];
        setName(contact.name);
        setEmail(contact.email);
        setSubject(contact.subject);
        setMessage(contact.message);
      } else {
        console.error(`No contact found with ID: ${id}`);
      }
    };
  
    fetchData();
  }, [id]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const index = id - 1;
      const contactDoc = doc(db, "formData", "contactDoc");
      const contactData = (await getDoc(contactDoc)).data();
  
      if (contactData && contactData.contact) {
        const contact = contactData.contact;
        contact[index] = {
          name,
          email,
          subject,
          message
        };
  
        await updateDoc(contactDoc, { contact });
      }
  
      navigate(`/query/${id}`);
    } catch (error) {
      console.error("Error updating query: ", error);
    }
  };

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="new">
  <div className="newContainer">
    <div className="top">
      <h1>Update Query</h1>
    </div>
    <div className="bottom">
      <div className="right">
        <form onSubmit={handleSubmit}>
          <div className="formInput">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
      </div>
  );
};

export default QueryUpdate;