import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "./testimonialdatasource";
import "./testimonialdata.scss";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const Testimonialdata = () => {
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  useEffect(() => {
    const testimonialDoc = doc(db, "testimonials", "testDoc");

    const unsubscribe = onSnapshot(testimonialDoc, (docSnap) => {
      if (docSnap.exists()) {
        setData(
          docSnap.data().testimonials.map((item, index) => ({
            ...item,
            id: index + 1, // add an id property to each row, starting from 1
          }))
        );
      } else {
        console.log("No such document!");
        setData([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async (id) => {
    // Filter out the item to be deleted
    const newData = data.filter((item) => item.id !== id);
    setData(newData);

    // Update the document in Firestore
    const testimonialDoc = doc(db, "testimonials", "testDoc");
    const oldData = (await getDoc(testimonialDoc)).data().testimonials;
    oldData.splice(id - 1, 1); // remove the item at index id - 1
    await updateDoc(testimonialDoc, { testimonials: oldData });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/testimonial/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/testimonial/update/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="testimonialdata">
      <div className="testimonialdataTitle">
        Live Testimonials
        <Link to="/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Testimonialdata;
