import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const QuoteDetail = () => {
  const { id } = useParams();
  const [quoteData, setQuoteData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const quotesDoc = doc(db, "formData", "quotesDoc");
          const data = (await getDoc(quotesDoc)).data().quotes;
          const quote = data[Number(id) - 1];
          setQuoteData(quote);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

    fetchData();
  }, [id]);

  if (!quoteData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className='quotes-detail'>Loading...</div>
    </div>
      </div>
    );
  }

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
    <div className="quotes-detail">
      <h1>Quote Detail</h1>
      {/* Display the quote data here. This is just an example, you'll need to adjust this based on the structure of your data. */}
      <p>Name: {quoteData.name}</p>
    <p>Email: {quoteData.email}</p>
    <p>Mobile: {quoteData.mobile}</p>
    <p>Service: {quoteData.service}</p>
    <Link to="/services">
      <button>Go Back</button>
    </Link>
  </div>
    </div>
      </div>
  );
};

export default QuoteDetail;