import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import "./table.scss";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { formatDistanceToNow } from "date-fns";

const fetchLatestUpdates = async () => {
  const db = getFirestore();
  const updates = [];

  // Define an array of document details
  const docs = [
    { collection: "formData", docName: "contactDoc", arrayName: "contact" },
    { collection: "formData", docName: "quotesDoc", arrayName: "quotes" },
    { collection: "formData", docName: "serviceDoc", arrayName: "service" },
    { collection: "formData", docName: "testimonialDoc", arrayName: "testimonials" },
    { collection: "blogs", docName: "blogDoc", arrayName: "blogs" }
  ];

  for (const { collection, docName, arrayName } of docs) {
    const docRef = await getDoc(doc(db, collection, docName));
    if (docRef.exists()) {
      const data = docRef.data()[arrayName];
      data.forEach((item, index) => {
        // Check if the item is visible
        if (item.isVisible !== false) {
          let timestamp;
          if (docName === "blogDoc") {
            timestamp = new Date(item.date); // Use the date field for blogs
          } else {
            timestamp = item.timestamp?.toDate();
          }

          if (timestamp) {
            const isTestimonialDoc = docName === "testimonialDoc";
            const isBlogDoc = docName === "blogDoc";
            updates.push({
              page: docName.replace("Doc", ""),
              id: docRef.id + index,
              name: isBlogDoc ? item.title : (isTestimonialDoc ? item.fullName : item.name),
              originalTimestamp: timestamp,
            });
          }
        }
      });
    }
  }

  // Sort updates by the original timestamp
  updates.sort((a, b) => b.originalTimestamp - a.originalTimestamp);

  // Format timestamps for display after sorting
  updates.forEach((update) => {
    update.timestamp = formatDistanceToNow(update.originalTimestamp, {
      addSuffix: true,
    });
    delete update.originalTimestamp; // Optional: remove the original timestamp if not needed anymore
  });

  return updates;
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "20px",
      color: "#F3B457",
    },
    "& .MuiDataGrid-cell": {
      borderRight: "1px solid #ccc",
      color: "black",
    },
  },
});

const List = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchLatestUpdates().then((updates) => {
      setRows(updates);
    });
  }, []);

  const columns = [
    { field: "page", headerName: "Page", width: 100 },
    { field: "name", headerName: "Name", width: 140 },
    { field: "timestamp", headerName: "Timestamp", width: 170 },
  ];

  const classes = useStyles();

  return (
    <div style={{ height: 350, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        rowHeight={45}
        className={classes.root}
      />
    </div>
  );
};

export default List;
