// testimonialFormDatasource.js
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const userColumns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "fullName",
    headerName: "Full Name",
    width: 110,
  },
  {
    field: "designation",
    headerName: "Designation",
    width: 110,
  },
  {
    field: "companyName",
    headerName: "Company Name",
    width: 120,
  },
  {
    field: "companyEmail",
    headerName: "Company Email",
    width: 120,
  },
  {
    field: "positiveReview",
    headerName: "Positive Review",
    width: 120,
  },
  {
    field: "feedback",
    headerName: "Feedback",
    width: 120,
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 130,
  },
];

export const fetchData = async () => {
  const docRef = doc(db, 'formData', 'testimonialDoc');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().testimonials.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
  } else {
    console.log('No such document!');
    return [];
  }
};