export const userInputs = [
  {
    id: 1,
    label: "Client's Name",
    type: "text",
    name: "name",
    placeholder: "Enter name here...",
  },
  {
    id: 2,
    label: "Client's Designation",
    type: "text",
    name: "designation",
    placeholder: "Enter designation here...",
  },
  {
    id: 3,
    label: "Client's Business Name",
    type: "text",
    name: "businessName",
    placeholder: "Enter business name here...",
  },
  {
    id: 4,
    label: "Client's Testimonial",
    type: "text",
    name: "testimonial",
    placeholder: "Enter testimonial here...",
  },
];

export const sliderInputs = [
  {
    id: 1,
    label: "Title 1",
    type: "text",
    name: "title1",
    placeholder: "Enter first title",
  },
  {
    id: 2,
    label: "Title 2",
    type: "text",
    name: "title2",
    placeholder: "Enter second title",
  },
];

export const blogInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    name: "title",
    placeholder: "Enter blog title",
  },
  {
    id: 2,
    label: "Meta Description",
    type: "text",
    name: "metaDescription",
    placeholder: "Enter blog description",
  },
  {
    id: 3,
    label: "Content",
    type: "text",
    name: "content",
    placeholder: "Enter blog content",
  },
  {
    id: 4,
    label: "Author",
    type: "text",
    name: "author",
    placeholder: "Enter blog author",
  },
  {
    id: 5,
    label: "Date",
    type: "date",
    name: "date",
    placeholder: "Enter blog date",
  },  
  {
    id: 6,
    label: "Labels",
    type: "text",
    name: "labels",
    placeholder: "Enter blog labels",
  },
];