import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const ClientUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clientData, setClientData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const clientDoc = doc(db, "clients", "clientDoc");
      const clientData = (await getDoc(clientDoc)).data();
      const index = id - 1;

      if (clientData && clientData.clients && clientData.clients[index]) {
        setClientData(clientData.clients[index]);
      } else {
        console.error(`No client found with ID: ${id}`);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      let logoUrl = "";
      let fileName = "";
  
      if (clientData.clientLogo instanceof Blob) {
        const storageRef = ref(
          storage,
          `clients/${clientData.clientLogo.name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          clientData.clientLogo
        );
  
        fileName = clientData.clientLogo.name;
  
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              reject(error);
            },
            async () => {
              logoUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } else {
        logoUrl = clientData.clientLogo;
        fileName = clientData.fileName;
      }
  
      const index = id - 1;
      const clientDoc = doc(db, "clients", "clientDoc");
      const clientDataArray = (await getDoc(clientDoc)).data().clients;
  
      if (clientDataArray) {
        clientDataArray[index] = {
          ...clientData,
          clientLogo: logoUrl,
          fileName: fileName,
          timestamp: new Date(),
        };
  
        await updateDoc(clientDoc, { clients: clientDataArray });
      }
  
      navigate(`/client/${id}`);
    } catch (error) {
      console.error("Error updating client: ", error);
    }
  };

  const handleFileChange = (e) => {
    setClientData({
      ...clientData,
      clientLogo: e.target.files[0],
    });
  };

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="new">
      <div className="newContainer">
        <div className="top">
          <h1>Update Client</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                clientData.clientLogo instanceof Blob
                  ? URL.createObjectURL(clientData.clientLogo)
                  : clientData.clientLogo ||
                    "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Upload Logo
                  <br />
                  (Suggested dimensions:<br/> 120px x 40px):{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  name="clientLogo"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: "none" }}
                />
              </div>

              <button type="submit">Update Client</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default ClientUpdate;