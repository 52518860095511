import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const ServiceUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [services, setServices] = useState([]);
  const isMounted = useRef(true);
  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const serviceDoc = doc(db, "formData", "serviceDoc");
      const serviceData = (await getDoc(serviceDoc)).data();
      const index = id - 1;
  
      if (serviceData && serviceData.service && serviceData.service[index]) {
        const service = serviceData.service[index];
        setName(service.name);
        setEmail(service.email);
        setMobile(service.mobile);
        setServices(service.services);
      } else {
        console.error(`No service found with ID: ${id}`);
      }
    };
  
    fetchData();
  }, [id]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const index = id - 1;
      const serviceDoc = doc(db, "formData", "serviceDoc");
      const serviceData = (await getDoc(serviceDoc)).data();
  
      if (serviceData && serviceData.service) {
        const services = serviceData.service;
        services[index] = {
          name,
          email,
          mobile,
          services
        };
  
        await updateDoc(serviceDoc, { service: services });
      }
  
      if (isMounted.current) {
        navigate(`/quote/${id}`);
      }
    } catch (error) {
      console.error("Error updating service: ", error);
    }
  };

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="new">
  <div className="newContainer">
    <div className="top">
      <h1>Update Service</h1>
    </div>
    <div className="bottom">
      <div className="right">
        <form onSubmit={handleSubmit}>
          <div className="formInput">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Mobile</label>
            <input
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Services</label>
            <input
              type="text"
              value={services.join(', ')}
              onChange={(e) => setServices(e.target.value.split(', '))}
            />
          </div>
          <button onClick={() => navigate(-1)}>Go Back</button>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
      </div>
  );
};

export default ServiceUpdate;