import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const userColumns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "name",
    headerName: "Client's Name",
    width: 180,
  },
  {
    field: "designation",
    headerName: "Client's Designation",
    width: 160,
  },
  {
    field: "businessName",
    headerName: "Client's Business Name",
    width: 220,
  },
  {
    field: "testimonial",
    headerName: "Client's Testimonial",
    width: 360,
  },
];

export const fetchData = async () => {
  const docRef = doc(db, 'testimonials', 'testDoc');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().testimonials.map((item, index) => ({
      ...item,
      id: index+1,
    }));
  } else {
    console.log('No such document!');
    return [];
  }
};