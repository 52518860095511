import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const TestimonialUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const testimonialDoc = doc(db, "testimonials", "testDoc");
      const testimonialData = (await getDoc(testimonialDoc)).data();
      const index = id - 1;
  
      if (testimonialData && testimonialData.testimonials && testimonialData.testimonials[index]) {
        const testimonial = testimonialData.testimonials[index];
        setName(testimonial.name);
        setDesignation(testimonial.designation);
        setBusinessName(testimonial.businessName);
        setTestimonial(testimonial.testimonial);
        setImageUrl(testimonial.imageUrl);
      } else {
        console.error(`No testimonial found with ID: ${id}`);
      }
    };
  
    fetchData();
  }, [id]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      let newImageUrl = imageUrl;
  
      if (file) {
        const storageRef = ref(storage, `testimonial/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              reject(error);
            },
            async () => {
              newImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      }
  
      const index = id - 1;
      const testimonialDoc = doc(db, "testimonials", "testDoc");
      const testimonialData = (await getDoc(testimonialDoc)).data();
  
      if (testimonialData && testimonialData.testimonials) {
        const testimonials = testimonialData.testimonials;
        testimonials[index] = {
          name,
          designation,
          businessName,
          testimonial,
          imageUrl: newImageUrl,
        };
  
        await updateDoc(testimonialDoc, { testimonials });
      }
  
      navigate(`/testimonial/${id}`);
    } catch (error) {
      console.error("Error updating testimonial: ", error);
    }
  };

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="new">
  <div className="newContainer">
    <div className="top">
      <h1>Update Testimonial</h1>
    </div>
    <div className="bottom">
      <div className="left">
      <img
            src={
              file instanceof Blob
                ? URL.createObjectURL(file)
                : imageUrl || "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
            }
            alt=""
          />
      </div>
      <div className="right">
        <form onSubmit={handleSubmit}>
          <div className="formInput">
            <label htmlFor="file">
            Upload Image<br/>(Suggested dimensions:<br/> 40px x 40px): <DriveFolderUploadOutlinedIcon className="icon" />
            </label>
            <input
              type="file"
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>

          <div className="formInput">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Designation</label>
            <input
              type="text"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Business Name</label>
            <input
              type="text"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>

          <div className="formInput">
            <label>Testimonial</label>
            <textarea
              value={testimonial}
              onChange={(e) => setTestimonial(e.target.value)}
            />
          </div>

          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
      </div>
  );
};

export default TestimonialUpdate;