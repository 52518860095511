import React from "react";
import "./Msidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CommentIcon from "@mui/icons-material/Comment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { NavLink } from "react-router-dom";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Msidebar = ({ open, onClose }) => {
  const navigate = useNavigate();

  const logoutUser = async (e) => {
    e.preventDefault();

    await signOut(auth);
    navigate("/", { replace: true });
  };

  React.useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.closest(".mobileSidebar") === null) {
        onClose();
      }
    };
  
    if (open) {
      document.addEventListener("click", handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open, onClose]); // Ensure onClose is also listed in the dependency array if it's a prop or state

  return (
    <div className={`mobileSidebar ${open ? "open" : ""}`}>
      <span className="navTitle">Admin Panel</span>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>

          <NavLink
            to="/home"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </NavLink>
          <p className="title">MODULES</p>
          <NavLink
            to="/slider"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <SlideshowIcon className="icon" />
              <span>Sliders</span>
            </li>
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <EditNoteIcon className="icon" />
              <span>Blogs</span>
            </li>
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <ContactMailIcon className="icon" />
              <span>Services Form</span>
            </li>
          </NavLink>
          <NavLink
            to="/clients"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <AccountCircleIcon className="icon" />
              <span>Clients</span>
            </li>
          </NavLink>
          <NavLink
            to="/testimonials"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <CommentIcon className="icon" />
              <span>Testimonials</span>
            </li>
          </NavLink>
          <NavLink
            to="/queries"
            className={({ isActive }) => (isActive ? "active-link" : "")}
            style={{ textDecoration: "none" }}
          >
            <li>
              <ContactSupportIcon className="icon" />
              <span>Contact Form</span>
            </li>
          </NavLink>
          <p className="title">USER</p>
          <NavLink to="/profile" style={{ textDecoration: "none" }}></NavLink>
          <li onClick={logoutUser}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Msidebar; 