import React from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Mnavbar from "../../components/mobile-navbar/Mnavbar"; 
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import useMediaQuery from '@mui/material/useMediaQuery';

const Home = () => {
  const matches = useMediaQuery('(max-width:600px)'); 

  return (
    <div className="home">
      {!matches && <Sidebar />}
      <div className="homeContainer">
        {matches ? <Mnavbar /> : <Navbar />}
        <div className="widgets">
          <Widget type="client" />
          <Widget type="blog" />
          <Widget type="slider" />
          <Widget type="testimonial" />          
          <Widget type="contact" />
          <Widget type="service" />          
        </div>
        <div className="charts">
        <div className="listContainer">
          <div className="listTitle">Latest Updates</div>
          <Table />
        </div>
          <Chart title="Modules Data" aspect={2.5 / 1} />
        </div>
      </div>
    </div>
  );
};

export default Home;