import "./query.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Querydata from "../../components/data/querydata/Querydata"
import useMediaQuery from '@mui/material/useMediaQuery';
import Mnavbar from "../../components/mobile-navbar/Mnavbar"; 

const Query = () => {
  const matches = useMediaQuery('(max-width:600px)'); 
  return (
    <div className="quote">
      {!matches && <Sidebar />}
      <div className="quoteContainer">
      {matches ? <Mnavbar /> : <Navbar />}
        <Querydata/>
      </div>
    </div>
  )
}

export default Query