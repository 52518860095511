import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const ClientDetail = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientDoc = doc(db, "clients", "clientDoc");
        const data = (await getDoc(clientDoc)).data().clients;
        const client = data[Number(id) - 1];
        setClientData(client);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [id]);

  if (!clientData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
          <div className="client-detail">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="client-detail">
          <h1>Client Detail</h1>
          {/* Display the client data here. This is just an example, you'll need to adjust this based on the structure of your data. */}
          <img src={clientData.clientLogo} alt="Not found!" />
          <br />
          <p>{clientData.fileName}</p>
          <Link to="/clients">
            <button>Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClientDetail;