import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const userColumns = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "name",
    headerName: "Name",
    width: 120,
  },
  {
    field: "email",
    headerName: "Email",
    width: 220,
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 150,
  },
  {
    field: "service",
    headerName: "Service",
    width: 240,
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 200,
  },
];

export const fetchData = async () => {
  const docRef = doc(db, 'formData', 'quotesDoc');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().quotes.map((item, index) => ({
      ...item,
      id: index+1,
    }));
  } else {
    console.log('No such document!');
    return [];
  }
};