import { useState, useContext } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext"; // import UserContext
import logo from "../../components/sidebar/logo.png";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext); // get setUser from UserContext
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");

  const loginWithUsernameAndPassword = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user); // update user state
      navigate("./home");
    } catch {
      setNotice("You entered a wrong username or password.");
    }
  };

  return (
    <div className="login-background">
      <div className="container">
        <div className="row justify-content-center d-flex align-items-center vh-100 pb-5 mx-2">
          <form
            className="col-md-4 mt-4 p-4"
            style={{
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="text-center mb-3">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            {"" !== notice && (
              <div className="alert alert-warning" role="alert">
                {notice}
              </div>
            )}
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
            </div>
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary pt-3 pb-3 loginButton"
                onClick={(e) => loginWithUsernameAndPassword(e)}
              >
                LOGIN
              </button>
            </div>
            <div className="mt-3 text-center"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
