import "./navbar.scss";

const Navbar = () => {

  return (
    <div className="navbar">
      <div className="wrapper">
        {/* <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div> */}
       <span className="navTitle">Admin Panel</span>
        <div className="items">
        </div>
      </div>
    </div>
  );
};

export default Navbar;
