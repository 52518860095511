import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const SliderDetail = () => {
  const { id } = useParams();
  const [sliderData, setSliderData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const sliderDoc = doc(db, "slider", "sliderDoc");
          const data = (await getDoc(sliderDoc)).data().slider;
          const slider = data[Number(id) - 1];
          setSliderData(slider);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

    fetchData();
  }, [id]);

  if (!sliderData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className='slider-detail'>Loading...</div>
    </div>
      </div>
    );
  }

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="slider-detail">
      <h1>Slider Detail</h1>
      {/* Display the slider data here. This is just an example, you'll need to adjust this based on the structure of your data. */}
      <img src={sliderData.sliderImg} alt="Not found!" />
      <p>Title 1: {sliderData.title1}</p> {/* Display the first title */}
      <p>Title 2: {sliderData.title2}</p> {/* Display the second title */}
      <Link to="/slider">
        <button>Go Back</button>
      </Link>
    </div>
    </div>
    </div>
  );
};

export default SliderDetail;