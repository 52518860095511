import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import "./testimonial.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Testimonialdata from "../../components/data/testimonialdata/Testimonialdata"
import TestimonialFormData from "../../components/data/testimonialFormData/TestimonialFormData"
import useMediaQuery from '@mui/material/useMediaQuery';
import Mnavbar from "../../components/mobile-navbar/Mnavbar"; 

const Testimonial = () => {
  const matches = useMediaQuery('(max-width:600px)'); 
  const [activeComponent, setActiveComponent] = useState('testimonials');
  return (
    <div className="testimonial">
      {!matches && <Sidebar />}
      <div className="testimonialContainer">
      {matches ? <Mnavbar /> : <Navbar />}
      <Link 
          className={`myLink ${activeComponent === 'testimonials' ? 'active' : ''}`} 
          onClick={() => setActiveComponent('testimonials')}
        >
          Approved Testmonials
        </Link>
        <Link 
          className={`myLink ${activeComponent === 'newTest' ? 'active' : ''}`} 
          onClick={() => setActiveComponent('newTest')}
        >
          New Testimonials
        </Link>
        <hr className='m-1'/>
        {activeComponent === 'testimonials' && <Testimonialdata/>}
        {activeComponent === 'newTest' && <TestimonialFormData/>}
      </div>
    </div>
  )
}

export default Testimonial