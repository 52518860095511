import { createContext, useState, useEffect, useCallback } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility

  const handleClose = () => {
    setOpenDialog(false);
  };

  const logoutUser = useCallback(async () => {
    setOpenDialog(true); // Show dialog before logging out
    await signOut(auth);
    setUser(null); // Ensure user state is set to null after logout
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Setup inactivity logout
  useEffect(() => {
    let logoutTimer;
    const resetLogoutTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        logoutUser(); // Automatically log out the user after 3 minutes of inactivity
      }, 180000); // 3 minutes in milliseconds
    };

    // Events to reset the timer on
    window.addEventListener("mousemove", resetLogoutTimer);
    window.addEventListener("keypress", resetLogoutTimer);
    window.addEventListener("click", resetLogoutTimer);

    resetLogoutTimer(); // Initialize the timer

    return () => {
      clearTimeout(logoutTimer); // Cleanup
      window.removeEventListener("mousemove", resetLogoutTimer);
      window.removeEventListener("keypress", resetLogoutTimer);
      window.removeEventListener("click", resetLogoutTimer);
    };
  }, [logoutUser]);

  return (
    <UserContext.Provider value={{ user, setUser, loading, logoutUser }}>
      {children}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logged Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have been logged out due to inactivity, please login again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
}