import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNZDditjKa0YJC6W5_4K_ubWiAhJv-Ux4",
  authDomain: "enrope-website.firebaseapp.com",
  projectId: "enrope-website",
  storageBucket: "enrope-website.appspot.com",
  messagingSenderId: "992708735810",
  appId: "1:992708735810:web:88cba1ebea8bcd072fcedb",
  measurementId: "G-5506JGWEF2"
};

// Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore and get a reference to the service
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);