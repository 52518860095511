import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const QueryDetail = () => {
  const { id } = useParams();
  const [queryData, setQueryData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contactDoc = doc(db, "formData", "contactDoc");
        const data = (await getDoc(contactDoc)).data().contact;
        const query = data[Number(id) - 1];
        setQueryData(query);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [id]);

  if (!queryData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
          <div className="query-detail">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="query-detail">
          <h1>Query Detail</h1>
          {/* Display the query data here. This is just an example, you'll need to adjust this based on the structure of your data. */}
          <p>Name: {queryData.name}</p>
          <p>Email: {queryData.email}</p>
          <p>Subject: {queryData.subject}</p>
          <p>Message: {queryData.message}</p>
          <Link to="/queries">
            <button>Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QueryDetail;
