import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "./querydatasource";
import "./querydata.scss";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const Querydata = () => {
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const contactDoc = doc(db, "formData", "contactDoc");

    const unsubscribe = onSnapshot(contactDoc, (docSnap) => {
      if (docSnap.exists()) {
        const rawData = docSnap.data().contact;
        const filteredData = rawData.filter(item => item.isVisible !== false); // Filter based on isVisible
        setData(
          filteredData.map((item, index) => {
            const timestamp = item.timestamp
              ? formatDistanceToNow(
                  new Date(item.timestamp.seconds * 1000), // convert Firestore Timestamp to JavaScript Date
                  { addSuffix: true } // option to add 'ago' after the time
                )
              : "Timestamp not available";

            return {
              ...item,
              id: index + 1, // add an id property to each row, starting from 1
              timestamp,
            };
          })
        );
      } else {
        console.log("No such document!");
        setData([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async (id) => {
    // Update the local state to set isVisible to false for the deleted item
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, isVisible: false };
      }
      return item;
    });
    setData(updatedData);

    // Update the document in Firestore
    const contactDoc = doc(db, "formData", "contactDoc");
    const docSnap = await getDoc(contactDoc);
    if (docSnap.exists()) {
      const oldData = docSnap.data().contact;
      const updatedDataInDb = oldData.map((item, index) => {
        if (index === id - 1) { // Assuming id is 1-indexed and matches array positions
          return { ...item, isVisible: false };
        }
        return item;
      });
    await updateDoc(contactDoc, { contact: updatedDataInDb });
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/query/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/query/update/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="querydata">
      <div className="querydataTitle">Contact Us Form Data</div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Querydata;
