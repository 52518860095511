import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const TestimonialDetail = () => {
  const { id } = useParams();
  const [testimonialData, setTestimonialData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const testimonialDoc = doc(db, "formData", "testimonialDoc");
          const data = (await getDoc(testimonialDoc)).data().testimonials;
          const testimonial = data[Number(id) - 1];
          setTestimonialData(testimonial);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

    fetchData();
  }, [id]);

  if (!testimonialData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className='testimonial-detail'>Loading...</div>
    </div>
      </div>
    );
  }

  return (
    <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
    <div className="testimonial-detail">
      <h1>Testimonial Detail</h1>
      {/* Display the testimonial data here. This is just an example, you'll need to adjust this based on the structure of your data. */}      
      <p>Full Name: {testimonialData.fullName}</p>
      <p>Designation: {testimonialData.designation}</p>
      <p>Company Name: {testimonialData.companyName}</p>
      <p>Company Email: {testimonialData.companyEmail}</p>
      <p>Positive Review: {testimonialData.positiveReview}</p>
      <p>Feedback: {testimonialData.feedback}</p>
      <Link to="/testimonials">
        <button>Go Back</button>
      </Link>
    </div>
    </div>
    </div>
  );
};

export default TestimonialDetail;