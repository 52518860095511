import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "./testimonialFormDatasource";
import "./testimonialFormData.scss";
import {
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatDistanceToNow } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const TestimonialFormData = () => {
  const [data, setData] = useState([]);
  const [approvedTestimonials, setApprovedTestimonials] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  useEffect(() => {
    const testimonialDoc = doc(db, "formData", "testimonialDoc");

    const unsubscribe = onSnapshot(testimonialDoc, (docSnap) => {
      if (docSnap.exists()) {
        setData(
          docSnap.data().testimonials.map((item, index) => {
            const timestamp = item.timestamp
              ? formatDistanceToNow(
                  new Date(item.timestamp.seconds * 1000), // convert Firestore Timestamp to JavaScript Date
                  { addSuffix: true } // option to add 'ago' after the time
                )
              : "Timestamp not available";
            return {
              ...item,
              id: index + 1,
              timestamp, // Step 2: Add formatted timestamp to each item
            };
          })
        );
      } else {
        console.log("No such document!");
        setData([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);

    const testimonialDoc = doc(db, "formData", "testimonialDoc");
    const oldData = (await getDoc(testimonialDoc)).data().testimonials;
    oldData.splice(id - 1, 1);
    await updateDoc(testimonialDoc, { testimonials: oldData });
  };

  const handleApprove = async (rowData) => {
    const testimonialDocRef = doc(db, "testimonials", "testDoc");
    const mappedData = {
      name: rowData.fullName,
      designation: rowData.designation,
      businessName: rowData.companyName,
      testimonial: rowData.positiveReview,
    };

    try {
      await updateDoc(testimonialDocRef, {
        testimonials: arrayUnion(mappedData),
      });
      // Show an alert to the user
      alert("Testimonial approved!");
      setApprovedTestimonials((prevState) => ({
        ...prevState,
        [rowData.id]: true,
      }));
    } catch (error) {
      console.error("Error updating document: ", error);
      // Optionally, handle the error with a user-friendly message
      alert("Failed to approve testimonial!");
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 290,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {approvedTestimonials[params.row.id] ? (
              <button className="approveButton">
                <CheckCircleIcon /> Approved
              </button>
            ) : (
              <button
                className="approveButton"
                onClick={() => handleApprove(params.row)}
              >
                Approve
              </button>
            )}
            <Link
              to={`/new-testimonial/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/new-testimonial/update/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="testimonialdata">
      <div className="testimonialdataTitle">
        Testimonials Form Data
        <Link to="/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TestimonialFormData;
