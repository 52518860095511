import "./testimonialForm.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import TestimonialFormData from "../../components/data/testimonialFormData/TestimonialFormData";
import useMediaQuery from '@mui/material/useMediaQuery';
import Mnavbar from "../../components/mobile-navbar/Mnavbar"; 


const TestimonialForm = () => {
  const matches = useMediaQuery('(max-width:600px)'); 
  return (
    <div className="testimonial">
      {!matches && <Sidebar />}
      <div className="testimonialContainer">
      {matches ? <Mnavbar /> : <Navbar />}
        <TestimonialFormData/>
      </div>
    </div>
  )
}

export default TestimonialForm