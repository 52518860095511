import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { blogColumns } from "../blogData/blogdatasource";
import "./blogdata.scss";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const BlogData = () => {
  const [blogData, setBlogData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/blog/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <Link to={`/blog/update/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="updateButton">Update</div>
            </Link>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const blogsDoc = doc(db, "blogs", "blogDoc");
      const docData = (await getDoc(blogsDoc)).data();

      if (docData && docData.blogs) {
        setBlogData(docData.blogs.map((item, index) => ({ ...item, id: index + 1 })));
      } else {
        console.error("No blog data found");
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async (id) => {
    const newBlogData = blogData.filter((item) => item.id !== id);
    setBlogData(newBlogData);

    const blogsDoc = doc(db, "blogs", "blogDoc");
    const oldBlogData = (await getDoc(blogsDoc)).data().blogs;
    oldBlogData.splice(id - 1, 1);
    await updateDoc(blogsDoc, { blogs: oldBlogData });
  };

  return (
    <div className="blogdata">
      <div className="blogdataTitle">
        Blog Form Data
        <Link to="/newblog" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={blogData}
        columns={blogColumns.concat(actionColumn)}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this blog?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteConfirmed(deleteId);
              setOpenDialog(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BlogData;