import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const ServiceDetail = () => {
  const { id } = useParams();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const serviceDoc = doc(db, "formData", "serviceDoc");
          const data = (await getDoc(serviceDoc)).data().service;
          const service = data[Number(id) - 1];
          setServiceData(service);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

    fetchData();
  }, [id]);

  if (!serviceData) {
    return (
      <div className="query">
        <Sidebar />
        <div className="queryContainer">
          <Navbar />
          <div className='service-detail'>Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="query">
      <Sidebar />
      <div className="queryContainer">
        <Navbar />
        <div className="service-detail">
          <h1>Service Detail</h1>
          <p>Name: {serviceData.name}</p>
          <p>Email: {serviceData.email}</p>
          <p>Mobile: {serviceData.mobile}</p>
          <p>Services: {serviceData.services.join(", ")}</p>
          <Link to="/services">
            <button>Go Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;