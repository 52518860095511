import "./client.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, arrayUnion, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Newclient = ({ title }) => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      let fileUrl = '';
      let fileName = '';
  
      // Only upload the file if one was selected
      if (file) {
        // Create a reference to the file in Firebase Storage
        const storageRef = ref(storage, 'clients/' + file.name);
  
        // Upload the file to Firebase Storage
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        // Wait for the upload to complete
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Handle progress updates here if needed
            },
            (error) => {
              // Handle unsuccessful uploads
              reject(error);
            },
            () => {
              // Handle successful uploads
              resolve();
            }
          );
        });
  
        // Get the download URL of the file
        fileUrl = await getDownloadURL(storageRef);
  
        // Get the file name
        fileName = file.name;
      }
  
      // Prepare the data to be added to the array
      const data = {
        clientLogo: fileUrl,
        fileName: fileName,
        timestamp: new Date(),
      };
  
      // Get a reference to the 'clientDoc' document in the 'clients' collection
      const docRef = doc(db, 'clients', 'clientDoc');
  
      // Add the data to an array field in the 'clientDoc' document
      await updateDoc(docRef, {
        clients: arrayUnion(data),
      });
  
      alert('Client added successfully');
      navigate("/clients");
    } catch (error) {
      console.error('Error adding client: ', error);
    }finally {
      setIsLoading(false); // Set loading to false when operation is done
    }
  };

  if (isLoading) {
    return <div className="new">
    <Sidebar />
    <div className="newContainer">
      <Navbar />
      <div className='client-detail'>Loading...</div>
    </div>
  </div>;
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Upload Client Logo<br/>(Suggested dimensions:<br/> 120px x 40px): <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
  
              <button type="submit">Add Logo</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newclient;