import "./slider.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, arrayUnion, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";


const Newslider = ({ inputs, title }) => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title1: "",
    title2: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let fileUrl = '';
    
      // Only upload the file if one was selected
      if (file) {
        // Create a reference to the file in Firebase Storage
        const storageRef = ref(storage, 'slider/' + file.name); // Changed from 'testimonial/' to 'slider/'
    
        // Upload the file to Firebase Storage
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        // Wait for the upload to complete
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Handle progress updates here if needed
            },
            (error) => {
              // Handle unsuccessful uploads
              reject(error);
            },
            () => {
              // Handle successful uploads
              resolve();
            }
          );
        });
    
        // Get the download URL of the file
        fileUrl = await getDownloadURL(storageRef);
      }
    
      // Prepare the data to be added to the array
      const data = {
        ...formData,
        sliderImg: fileUrl, // Changed from imageUrl to sliderImg
      };
  
      // Check that all fields in formData are not empty
      for (const [key, value] of Object.entries(formData)) {
        if (!value) {
          console.error(`Field ${key} is empty`);
          return;
        }
      }
  
      // Get a reference to the 'sliderDoc' document in the 'slider' collection
      const docRef = doc(db, 'slider', 'sliderDoc'); // Changed from 'testimonials' and 'testDoc' to 'slider' and 'sliderDoc'
  
      // Add the data to an array field in the 'sliderDoc' document
      await updateDoc(docRef, {
        slider: arrayUnion(data), // Changed from testimonials to slider
      });
  
      alert('Slider added successfully'); // Changed from 'Testimonial submitted successfully' to 'slider added successfully'
      navigate("/slider");
    } catch (error) {
      console.error('Error adding slider: ', error); // Changed from 'Error submitting testimonial: ' to 'Error adding slider: '
    } finally {
      setIsLoading(false); // Set loading to false when operation is done
    }
  };

  if (isLoading) {
    return <div className="new">
    <Sidebar />
    <div className="newContainer">
      <Navbar />
      <div className='slider-detail'>Loading...</div>
    </div>
  </div>;
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Upload Slider Image<br/>(Suggested dimensions:<br/> 1920px x 1080px): <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
  
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    type={input.type}
                    name={input.name}
                    placeholder={input.placeholder}
                    value={formData[input.name]}
                    onChange={handleChange}
                  />
                </div>
              ))}
              <button type="submit">Add Slider</button> {/* Changed from 'Add' to 'Add slider' */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newslider;