import { auth } from "../../firebase";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const Profile = () => {
  const user = auth.currentUser;

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 text-center">
              <AccountCircleOutlinedIcon className="icon" />
              <p>
                Welcome{" "}
                <em className="text-decoration-underline">{user.email}</em>. You are logged in!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
